// == Titles ==
.col-s-title{
  padding-top: 20px;
  padding-bottom: 14px;

  .title{
    color: $orange;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
  }
}
// == ==
