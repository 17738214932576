#results-page{

  .products-section{
    padding-bottom: 20px;

    .category-title{
      text-align: center;
      font-size: 2.0rem;
      font-weight: 400;
    }

  }
}
