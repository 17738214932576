#about-us-page{
  padding-top: 20px;
  padding-bottom: 20px;
  color: #565656;

  p{
    font-size: 1.05rem;
  }

  .info-section{
    .col-info{
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: justify;

      .box-image{
        display: inline-block;
        margin-bottom: 15px;
        width: 100%;

        img{
          width: 100%;
        }
      }

      .title{
        margin-bottom: 10px;
        color: $blue;
        font-weight: 500;
        font-size: 1.6rem;
        text-align: center;
      }
    }

    .col-why{
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: justify;

      .box-icon{
        display: inline-block;
        margin-bottom: 15px;
        width: 100%;
        text-align: center;

        img{

        }
      }

      .title{
        margin-bottom: 10px;
        color: #565656;
        font-weight: 700;
        font-size: 1.65rem;
        text-align: center;
      }
    }
  }

  .info-color-section{
		padding-top: 18px;
		padding-bottom: 35px;
    margin-top: 20px;
		margin-bottom: 15px;
		color: #ffffff;
		background-color: $orange;

    .col-s-title{
      margin-bottom: 10px;

      .title{
        color: #ffffff;
      }
    }

		.col-info{
			margin-top: 14px;
			margin-bottom: 14px;
      font-size: 1.05rem;
			text-align: center;

      .title{
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 1.6rem;
        text-align: center;
      }

			ul,ol{
				padding-left: 19px;
				text-align: justify;

				li{
					margin-top: 4px;
					margin-bottom: 4px;
				}
			}
		}

    @media screen and (max-width: 767px){
      .col-info{
        margin-top: 4px;
        margin-bottom: 4px;
        text-align: center;

        ul,ol{
          text-align: center;
        }
      }
    }
	}

}
